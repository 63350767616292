@font-face {
	font-family: 'Jakarta';
	src: url('./fonts/PlusJakartaSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-weight: bold;
}

@font-face {
	font-family: 'Jakarta';
	src: url('./fonts/PlusJakartaSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-weight: normal;
}

.body{
    font-family: 'Jakarta';
    color: #000000;
}
.container{
    margin: 30px 0px 40px;
    height: 100%;
}
.row{
    display: flex;
}
.col3{
    width: 100%;
    max-width: calc(8.33% * 3);
}
.col9{
    width: 100%;
    max-width: calc(8.33% * 9);
}
.col6{
    width: 100%;
    max-width: 50%;
}
.logo{
    width: 60px;
}
.h1{
    font-weight: normal;
    font-size: 15px;
    margin: 0;
}
.h2{
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    margin: 0;
}

.textRight{
    text-align: right;
}
.pr10{
    padding-right: 10px;
}
.pr50{
    padding-right: 50px;
}
.mb10{
    margin-bottom: 10px;
}
.mb20{
    margin-bottom: 20px;
}
.mr10{
    margin-right: 10px;
}
.alignItemsCenter{
    align-items: center;
}
@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) {
    footer{
        margin-top: 170px;
    }
}}
@-moz-document url-prefix() {
    footer {
        margin-top: 250px;
    }
}