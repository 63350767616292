@font-face {
	font-family: "Jakarta";
	src: url("./fonts/PlusJakartaSans-Bold.ttf") format("truetype");
	font-weight: 700;
	font-weight: bold;
}

@font-face {
	font-family: "Jakarta";
	src: url("./fonts/PlusJakartaSans-Regular.ttf") format("truetype");
	font-weight: 400;
	font-weight: normal;
}

@page {
	size: A4;
	margin: 0mm;
}
.mkTableCell {
	width: 17%;
}
.container .bodyBold {
	margin-bottom: 10px;
}
.footer {
    margin-bottom: 20px;
}
.footer .bodyRegular {
    margin-left: 25px;
}
.diseaseList .mb20 {
    margin-left: 25px;
}
.body {
	font-family: "Jakarta";
	color: #2d2d2d;
	-webkit-print-color-adjust: exact !important;
	color-adjust: exact;
	margin: 0px;
	padding: 0px;
	border: 0px;
}
.container {
	margin: 20px 50px 23px 50px;
	height: 100%;
}

.row {
	display: flex;
}
.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9,
.col10,
.col11,
.col12,
.col24 {
	width: 100%;
}
.col1 {
	max-width: calc(8.33% * 1);
}
.col2 {
	max-width: calc(8.33% * 2);
}
.col3 {
	max-width: calc(8.33% * 3);
}
.col4 {
	max-width: calc(8.33% * 4);
}
.col5 {
	max-width: calc(8.33% * 5);
}
.col6 {
	max-width: 50%;
}
.col7 {
	max-width: calc(8.33% * 7);
}
.col8 {
	max-width: calc(8.33% * 8);
}
.col9 {
	max-width: calc(8.33% * 9);
}
.col10 {
	max-width: calc(8.33% * 10);
}
.col11 {
	max-width: calc(8.33% * 11);
}
.col11 {
	max-width: 100%;
}
.col24 {
	max-width: calc(8.33% * 2.4);
}
.logo {
	width: 40px;
}
.h1 {
	font-weight: normal;
	font-size: 15px;
	margin: 0;
}
.h2 {
	font-weight: bold;
	font-size: 10px;
	line-height: 16px;
	margin: 0;
}
.leadBold {
	font-weight: 700;
	font-size: 13px;
	line-height: 23px;
	margin: 0;
}
.leadRegular {
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	margin: 0;
}
.bodyBold {
	font-weight: 700;
	font-size: 12px;
	line-height: 18px;
	margin: 0;
}
.bodyRegular {
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
	margin: 0;
}
.bodyBigRegular {
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	margin: 0;
}
.bodyBigBold {
	font-weight: 700;
	font-size: 15px;
	line-height: 16px;
	margin: 0;
}
.bodySmallRegular {
	font-weight: 400;
	font-size: 13px;
	line-height: 17px;
	margin: 0;
}
.col11 .legalBold {
	font-weight: 700;
	font-size: 9px;
	line-height: 9px;
	margin: 0;
}
.col11 .legalRegular {
	font-weight: 400;
	font-size: 8px;
	line-height: 10px;
	margin: 0;
    margin-left: 5px;
}
.legalBold {
	font-weight: 700;
	font-size: 13px;
	line-height: 9px;
	margin: 0;
}
.legalRegular {
	font-weight: 400;
	font-size: 11px;
	line-height: 13px;
	margin: 0;
}
.headingRegular {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
}
.headingBold {
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
}
.textRight {
	text-align: right;
}
.textCenter {
	text-align: center;
}
.pr10 {
	padding-right: 10px;
}
.pr50 {
	padding-right: 50px;
}
.mb5 {
	margin-bottom: 5px;
}
.mb10 {
	margin-bottom: 10px;
}
.mb20 {
	margin-bottom: 20px;
}
.mb50 {
	margin-bottom: 50px;
}
.mb40 {
	margin-bottom: 40px;
}
.mt30 {
	margin-top: 30px;
}
.mt20 {
	margin-top: 20px;
}
.mr10 {
	margin-right: 10px;
}
.mr5 {
	margin-right: 5px;
}
.px60 {
	padding-left: 60px;
	padding-right: 60px;
}
.alignItemsCenter {
	align-items: center;
}
.justifyContentBetween {
	justify-content: space-between;
}
.dFlex {
	display: flex;
}
.w100 {
	width: 100%;
}
.w50 {
	width: 50%;
}
.table th {
	text-align: left;
	padding: 2px;
}
.table td {
	padding: 2px;
}
.table.fixed {
	table-layout: fixed;
}
.bigTable {
	text-align: center;
}
.bigTable th {
	text-align: center;
	padding: 14px 5px;
}
.bigTable td {
	padding: 14px 5px;
}
.bigTable .textLeft {
	text-align: left;
}
tbody tr:nth-child(odd) {
	background-color: #f9fafc;
}
.label {
	padding: 5px 10px;
	color: #fff;
}
.label.grey {
	background-color: #f9fafc;
	color: #2d2d2d;
}
.label.highRisk {
	background-color: #c9424a;
}
.label.risk {
	background-color: #e77d5c;
}
.label.carrier {
	background-color: #ad9914;
}
.label.clear {
	background-color: #56a14d;
}
ul {
	padding-left: 10px;
}
header.highRisk {
	border-bottom: 1px solid #c9424a;
}
header.highRisk .tag {
	background-color: #c9424a;
}
header.risk {
	border-bottom: 1px solid #e77d5c;
}
header.risk .tag {
	background-color: #e77d5c;
}
header.carrier {
	border-bottom: 1px solid #ad9914;
}
header.carrier .tag {
	background-color: #ad9914;
}
header.clear {
	border-bottom: 1px solid #56a14d;
}
header.clear .tag {
	background-color: #56a14d;
}
.tag {
	font-weight: 700;
	font-size: 14px;
	line-height: 14px;
	color: #ffffff;
	padding: 2px 8px;
	border-radius: 24px;
}
.tag.high {
	background-color: #c9424a;
}
.tag.medium {
	background-color: #ad9914;
}
.tag.low {
	background-color: #56a14d;
}
@media print {
	footer {
		position: fixed;
		bottom: 23px;
	}
}
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		footer {
			position: static;
			margin-top: 40px;
		}
	}
}
